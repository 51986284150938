html,
body {
  /*overflow-x: hidden;*/ /* Prevent scroll on narrow devices */
}

body {
  padding-top: 56px;
  /* padding-bottom: 60px; */
}

nav {
	background-color: #5882FA;
}

main > div {
	background-color: #81BEF7;
}

span.location {
	display: inline-block;
	vertical-align: middle;
	padding-left: 5px;
}

span.weather {
	font-size: 1.55rem;
	color: #fff;
	font-weight: 500;
}

input[type=search] {
	border: none !important;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px; /* Height of navbar */
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition: transform .3s ease-in-out, visibility .3s ease-in-out;
  }
  .offcanvas-collapse.open {
    visibility: visible;
    transform: translateX(-100%);
  }
}

.navbar {
	transition-property: top;
	transition-duration: 0.3s;
	padding-top: 1px;
    padding-bottom: 1px;
}

.navbar-brand {
	display: grid;
}

.navbar-brand > img {
	margin: 0px 5px;
}

.detail-item {
	font-size: 1.25rem;
	padding: 5px 0;
	color: #fff;
}

.item-button {
	background-color: transparent;
    border: none;
    font-weight: bold;
}

.right-func {
	visibility: hidden;
}

.navbar-off {
	top: -56px;
}

.nav-scroller .nav {
  color: rgba(255, 255, 255, .75);
}

.nav-scroller .nav-link {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: .875rem;
  color: #6c757d;
}

.nav-scroller .nav-link:hover {
  color: #007bff;
}

.nav-scroller .active {
  font-weight: 500;
  color: #343a40;
}

.loadingBackground {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: white;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingText {
  margin-top: 20px;
  font: 1rem 'Noto Sans KR';
  text-align: center;
}

.bg-purple {
  background-color: #6f42c1;
}


.bd-placeholder-img {
	font-size: 1.125rem;
	text-anchor: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

@media ( min-width : 768px) {
	.bd-placeholder-img-lg {
		font-size: 3.5rem;
	}
}

.b-example-divider {
	height: 3rem;
	background-color: rgba(0, 0, 0, .1);
	border: solid rgba(0, 0, 0, .15);
	border-width: 1px 0;
	box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em
		rgba(0, 0, 0, .15);
}

.b-example-vr {
	flex-shrink: 0;
	width: 1.5rem;
	height: 100vh;
}

.bi {
	vertical-align: -.125em;
	fill: currentColor;
}

.nav-scroller {
	position: relative;
	z-index: 2;
	height: 2.75rem;
	overflow-y: hidden;
}

.nav-scroller .nav {
	display: flex;
	flex-wrap: nowrap;
	padding-bottom: 1rem;
	margin-top: -1px;
	overflow-x: auto;
	text-align: center;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
}
#storeList > div > div > div {
	text-decoration: none;
}

#storeList > div > div > div > span > font {
	margin-right: 10px;
    border: 1px solid #c7254e;
    border-radius: 3px;
    padding: 0px 3px;
	color: #c7254e;
}

#storeList > div > div > div > span {
	font-weight: bold;
}

#storeList > div > div span {
	margin-right: 10px;
}

#storeList > div > div {
	padding: 0px 3px;
	font-size: 0.907em;
}

.storeImg {
	background-color: #f2f2f2;
}

.FilterLabel {
	padding-left : 15px;
	color:#c7254e;
}

.storeDesc {
	width: 100%;
	min-height: 101px;
	background-color: #f2f2f2;
	margin: 10px 0px;
	padding: 10px;
	color: #333 !important;
	display: grid;
	align-items: center;
}

.storeDesc .search-naver {
	background-color: #03c75a !important;
	border-color: #03c75a !important;
}

.storeDesc p {
	font-size: 14px;
	margin-bottom: 0rem;
}

.storeDesc span {
	font-size: 14px;
}

.storeDesc a {
	text-align: right;
}

.storeDesc hr {
	margin: 2rem 0;
}

.storeDesc div span {
	display: inline-block;
    vertical-align: middle;
	line-height: 2em;
}

.storePoint > button {
	margin: 0;
  	border: none;
  	cursor: pointer;
  	font-size: 0.9rem;
  	padding: 12px 16px;
  	background: #d5d8ed;
  	color: #333;
	width: 100%;
}

span.event {
	padding: 2px 4px;
    font-size: 90%;
	font-weight: bold;
    color: #c7254e;
    background-color: #F5A9A9;
    border-radius: 3px;
	letter-spacing: 3px;
}

.totalPoint {
	padding: 30px;
}
.totalPoint > div:nth-child(1) {
	height: 100px;
	display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.totalPoint > div:nth-child(1) > p {
	font-size: 2.0em;
}
.totalPoint > div:nth-child(2) {
	height: 50px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.totalPoint > div:nth-child(2) > button {
	margin: 0;
  	border: none;
  	cursor: pointer;
  	font-size: 1rem;
  	padding: 12px 16px;
  	border-radius: 5px;
  	background: #d5d8ed;
  	color: #333;
}
.history {
	background-color: #f2f2f2;
	padding: 20px;
	font-size: 0.9em;
}
.history > div {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	margin: 6px 0px;
	padding: 0px;
	background-color: #fff;
    border-radius: 5px;
}
.history > div.month {
    display: inline-block;
    background-color: #dedede;
    border-radius: 20px;
    width: 100px;
    text-align: center;
}
.history > div span {
	margin: 0px 10px;
}

.selectFont {
	color : #B71C1C
}
.selectStore {
	background-color : palegoldenrod
}

.iw_inner {
	font-size: 14px;
}
footer {
	background-color: #5882FA !important;
	color: #fff;
	font-size: 13px;
	z-index: 9999;
}
footer div.inactive {
	text-decoration: none;
	/* color: #fff; */
	color: #BDBDBD;
}

footer div.active {
	color: #fff !important;
}

main {
	margin-bottom: 60px;
}
.navbar>.container-fluid {
	display: grid;
	grid-template-columns: 1fr 1fr 1.2fr;

}
h2 {
	text-align: center; 
	margin-bottom: 0;
}
.line1_color {
	background-color : #0d3692;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line2_color {
	background-color : #33a23d;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line3_color {
	background-color : #fe5d10;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line4_color {
	background-color : #00a2d1;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line5_color {
	background-color : #8b50a4;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line6_color {
	background-color : #c55c1d;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line7_color {
	background-color : #54640d;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line8_color {
	background-color : #f14c82;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line9_color {
	background-color : #aa9872;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_b_color {
	background-color : #ff8c00;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_nb_color {
	background-color : #c82127;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_air_color {
	background-color : #3681b7;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_gj_color {
	background-color : #73c7a6;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_gc_color {
	background-color : #32c6a6;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_im1_color {
	background-color : #7CA8D5;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
.line_sillim_color {
	background-color : #6789CA;
	font-weight : bold;
	color: #FFFFFF;
	width: 100%;
}
#subwayList > div > div span {
	margin-right: 10px;
	margin-left: 10px;
}

#subwayList > div > div {
	padding: 0px 3px;
	font-size: 0.907em;
}
.station {
	display: grid;
    grid-template-columns: 1fr 1fr;
}
.station_right {
	text-align:right;
	color: #FFFFFF;
	margin-top: 10px;
}
.station_left {
	text-align:left;
	color: #FFFFFF;
	margin-top: 10px;
}
.station_loading {
	text-align:center;
}
.station_div_load {
	display: grid;
    grid-template-columns: 3.7fr 1fr 3.7fr;
}

.scroll{
	position:fixed;
	right:4%;
	bottom:11%;
	z-index:1;
}
.scroll > span{
	color: #5882FA;
}
main > div > table {
	width: 100%;
	font-size: medium;
	text-align: center;
}
main > div > table > tr > td {
	border-bottom: ridge;
	border-bottom-color: cadetblue;
	border-bottom-width: thin;
}

.modal-closed {
	position: relative;
}

.modal-open {
	position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
    height: 100%;
	overflow-y: auto;
	z-index: 1031;
}

.minus-index {
	position: fixed;
	z-index: -1;
}

.detail-container {
	margin-top: 65px;
}

.update-date {
	background-color: #FFFFFF;
	text-align: right;
	margin-top: 10px;
	font-size: 14px;
}

.rgba-0 {
	background-color: rgba(0, 0, 0, 0);
}

.crawl-menu {
	display: flex;
	justify-content: space-evenly;
}

#recommendList > div > div > div > div > div > div {
	text-decoration: none;
	line-height: 1.6;
}

#recommendList > div > div > div > div > div > div > span {
	font-weight: bold;
}

#recommendList > div > div span {
	margin-right: 10px;
	white-space: pre-wrap;
}

#recommendList > div > div > div > div > div {
	padding: 0px 3px;
	font-size: 0.907em;
}

.container.detail-container .storeDesc {
	grid-template-columns: 2fr 1fr !important;
}

.list-button {
	border-color: #046331 !important;
	background-color: #046331 !important;
}

input[type="file"] {
	display: none;
}

.camera {
	border: 0;
	background: none;
    color: #BDBDBD;
}

.recommend-bottom-color {
	border-bottom: solid 1.5px gray !important;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px); /* 전체 높이에서 헤더와 푸터의 높이(예: 100px)를 빼줍니다. */
    overflow: hidden;
}

.messages-list {
    overflow-y: auto;
    padding: 10px;
}

/* Chat container styles */
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 20px;
}

.chat-box {
    flex: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
}

/* Message styles */
.user-message {
    display: flex;
    justify-content: flex-end;
    margin: 8px;
}

.user-message p {
    background-color: #DCF8C6;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Message form styles */
.message-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #F0F0F0;
    border-top: 1px solid #ccc;
}

.message-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-right: 10px;
}

.send-button {
    padding: 10px 20px;
    border: none;
    border-radius: 8px;
    background-color: #007BFF;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}

.send-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.ai-message {
    display: flex;
    justify-content: flex-start;
    position: relative;
	background-color: #E8E8E8;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin: 0; /* 텍스트의 마진을 제거하여 색상이 텍스트에 적용되지 않도록 함 */
	width: fit-content;
	word-break: break-all;
}

.typing-indicator {
    position: absolute;
    bottom: -25px;
    right: 10px;
    font-size: 12px;
    color: #999;
}

.user-message p {
	text-align: left;
}

.ai-message p {
	margin-bottom: 0px;
}

nav.fixed-top {
	z-index: 1032;
}

.custom_overlay {
	z-index: -1;
    position: fixed;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
}